.ErrorPage {
	width: 100%;
	height: 100%;

	&__center {
		max-width: 1000px;
		padding-top: 15%;
		margin: 0 auto;
		text-align: center;
	}

	&__info {
		padding-bottom: 2em;
	}

	&__message {
		display: block;
		color: var(--color-red-500);
		font-size: 2em;
	}

	&__code {
		display: block;
		color: var(--color-gray-500);
		font-size: 1.5em;
	}
}
