.Table {
	width: 100%;
	border-spacing: 0;

	thead {
		background-color: var(--color-gray-200);
		text-align: left;
	}

	th {
		font-weight: 100;
		padding: .2rem .5rem;
		font-size: 1rem;
	}

	tbody {
		tr:nth-child(even) {
			background-color: var(--color-gray-100);
		}

		th {
			text-align: left;
		}

		td {
			padding: .2rem .5rem;
			font-size: 1rem;
		}

		tr {
			transition: background-color 20ms ease-in-out;

			&:hover {
				background-color: var(--color-gray-200);
			}
		}
	}

	&__cell {
		&--right {
			text-align: right;
		}
		&--nowrap  {
			white-space: nowrap;
		}
	}

	&__row {
		&--clickable {
			cursor: pointer;
		}
	}
}
