.FormTextArea {
	max-width: 100%;
	min-width: 100%;
	min-height: 120px;
	padding: .5rem .8rem;
	margin: 0;
	border: var(--color-gray-200) 1px solid;
	border-radius: 4px;
	background-color: var(--color-white);
	box-sizing: border-box;
	}
