.Button {
	display: inline-block;
	padding: .5rem 1rem;
	background-color: var(--color-blue-500);
	color: var(--color-white);
	border: none;
	border-radius: 0.25rem;
	outline: transparent;
	cursor: pointer;
	width: min-content;
	white-space: nowrap;
	text-decoration: none;
	font-size: 1rem;

	&:hover {
		background-color: var(--color-blue-600);
	}

	&:disabled {
		cursor: not-allowed;
		opacity: .4;
	}

	&--danger {
		background-color: var(--color-danger);

		&:hover {
			background-color: var(--color-red-600);
		}
	}

	&--success {
		background-color: var(--color-success);

		&:hover {
			background-color: var(--color-green-600);
		}
	}

	&--max {
		width: 100%;
		display: block;
	}

	&--slim {
		padding: .1rem 1rem;
	}

	&--link {
		&:hover {
			background-color: var(--color-blue-600);
		}
	}
}
