@import './variables.scss';
@import './fonts/index.scss';

html, body {
	width: 100%;
	margin: 0;
	padding: 0;
}

html, body, #root, .App {
	height: 100%;
}

body {
	font-size: 20px;
}

@import './layout.scss';
@import './components/index.scss';
