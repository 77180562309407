body {
	font-family: 'NunitoSans';
}

@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		url('./NunitoSans-Regular.woff2') format('woff2'), 
		url('./NunitoSans-Regular.ttf') format('tff');
}

@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src:
		url('./NunitoSans-Light.woff2') format('woff2'), 
		url('./NunitoSans-Light.ttf') format('tff');
}

@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src:
		url('./NunitoSans-Bold.woff2') format('woff2'), 
		url('./NunitoSans-Bold.ttf') format('tff');
}
