.SidePanel {
	grid-area: side-panel;
	display: grid;
	grid-template-areas: 'logo' 'user' 'navigation' 'footer';
	grid-template-rows: min-content min-content auto min-content;
	grid-gap: 1rem;
	background: var(--color-coal-900);
	color: var(--color-white);

	--padding: 1rem;
	--userActionTextSize: 1rem;
	--userActionIconSize: 20px;
	--navTextSize: 1.1rem;
	--navIconSize: 24px;

	&__logo {
		grid-area: logo;
		display: flex;
		padding: var(--padding);
		flex-direction: row;
		gap: .7em;
	}
	&__logo-image {
		width: 50px;
	}
	&__logo-text {
		font-size: .7em;
		text-transform: uppercase;
	}

	&__user {
		grid-area: user;
		padding: var(--padding);
	}
	&__user-title {
		font-weight: 400;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: var(--navTextSize);
	}
	&__user-thumbnail {
		width: 1.5rem;
		height: 1.5rem;
		display: inline-block;
	}
	&__user-actions {
		padding-left: var(--padding);
	}
	&__user-action {
		background-color: transparent;
		border: none;
		cursor: pointer;
		color: var(--color-white);
		transition: color 75ms ease-in;

		&:hover {
			color: var(--color-gray-500);
		}
	}
	&__user-action-icon {
		width: var(--userActionIconSize);
		height: var(--userActionIconSize);
	}
	&__user-action-text {
		font-size: var(--userActionTextSize);
		color: inherit;
	}

	&__navigation {
		grid-area: navigation;
		a {
			display: block;
			padding: .7rem var(--padding);
			color: var(--color-white);
			background-color: transparent;
			transition: all 100ms ease-in-out;
			text-decoration: none;

			&.active,
			&:hover {
				background-color: var(--color-coal-800);
			}
		}
	}
	&__navigation-icon {
		width: var(--navIconSize);
		height: var(--navIconSize);
	}
	&__navigation-text {
		font-size: var(--navTextSize);
	}
	&__footer {
		grid-area: footer;
		padding: var(--padding);
		font-size: .8rem;
		color: var(--color-gray-500);
	}

	&__title {
		margin: 0;
		padding: 0;
		font-size: 2rem;
	}
}
