.SearchField {
	font-size: .8rem;
    padding: 0.5em 0.5em 0.4em 0.5em;
	border: 1px solid var(--color-gray-200);
    border-radius: 2px;
	outline-color: var(--color-blue-500);

	&:focus {
		border-color: var(--color-blue-500);
	}
}
