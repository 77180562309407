.Badge {
	padding: .1rem .5rem;
	border-radius: 3px;
	color: var(--color-white);
	font-size: .8rem;

	&--default {
		background-color: var(--color-gray-500);
	}

	&--success {
		background-color: var(--color-success);
	}

	&--danger {
		background-color: var(--color-danger);
	}
}
