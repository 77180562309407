.EnvironmentInstanceSelect {
	display: inline-block;
	padding: .2rem;
	font-size: .8rem;
	border-radius: 3px;
	outline-color: var(--color-blue-500);
	min-width: 120px;
	max-width: 200px;

	&__label {
		min-width: 160px;
	}

	&__loader {
		color: var(--color-gray-400);
	}

	&--fetching {
		opacity: .5;
	}
}

.InputSwitch {
	text-decoration: none;
	display: flex;
  	justify-content: center;
}
.InputSwitch img {
	display: block;
	margin: auto;
	width: 20px;
	height: 20px;
}
