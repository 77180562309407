.Content {
	width: 100%;
	padding: 1rem 2rem 2rem 2rem;
	box-sizing: border-box;

	&__title,
	&__subtitle { 
		font-weight: 400;
		margin: 0;
	}

	&__title {
		margin: 0;
	}

	&__subtitle {
		padding: 15px 0;
		font-weight: 100;
	}
}
