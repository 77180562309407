:root {
	// COLORS

	// base
	--color-white: #ffffff;
	--color-black: #000000;

	// green
	--color-green-100: #f6fdf0;
	--color-green-200: #defccc;
	--color-green-300: #bef79b;
	--color-green-400: #77d93e;
	--color-green-500: #58bf13;
	--color-green-600: #41a103;
	--color-green-700: #306011;
	--color-green-800: #1b3609;
	--color-green-900: #101f04;

	// blue
	--color-blue-100: #f7fbfd;
	--color-blue-200: #ecf4fd;
	--color-blue-300: #c2dfff;
	--color-blue-400: #68b3ff;
	--color-blue-500: #408fec; // (brand color)
	--color-blue-600: #1774e2;
	--color-blue-700: #0e51a1;
	--color-blue-800: #092f5b;
	--color-blue-900: #05172d;


	// gray
	--color-gray-100: #f5f5f6;
	--color-gray-200: #edeeef;
	--color-gray-300: #dadde0;
	--color-gray-400: #c8ccd0;
	--color-gray-500: #a1a8ac;
	--color-gray-600: #7e8991;
	--color-gray-700: #4b5258;
	--color-gray-800: #32373b;
	--color-gray-900: #191b1d;

	// yellow
	--color-yellow-100: #fff9e0;
	--color-yellow-200: #ffefc7;
	--color-yellow-300: #fadb87;
	--color-yellow-400: #f5bd36;
	--color-yellow-500: #cc9700;
	--color-yellow-600: #b07500;
	--color-yellow-700: #825000;
	--color-yellow-800: #523600;
	--color-yellow-900: #2e1e00;

	// red
	--color-red-100: #fdf5f5;
	--color-red-200: #ffe5e3;
	--color-red-300: #ffccc7;
	--color-red-400: #ff8b85;
	--color-red-500: #ff5f5f;
	--color-red-600: #ff3029;
	--color-red-700: #d91714;
	--color-red-800: #960200;
	--color-red-900: #460000;

	// pink
	--color-pink-100: #fff2f9;
	--color-pink-200: #ffe6f4;
	--color-pink-300: #ffc9e8;
	--color-pink-400: #ffa3d9;
	--color-pink-500: #f777c0;
	--color-pink-600: #ed53ab;
	--color-pink-700: #c72687;
	--color-pink-800: #851157;
	--color-pink-900: #590437;

	// coal
	--color-coal-100: #f5f6ff;
	--color-coal-500: #9da1c2;
	--color-coal-800: #3d3c57;
	--color-coal-900: #302f4a;

	// alerts
	--color-info: var(--color-blue-200);
	--color-warning: var(--color-yellow-200);
	--color-danger: var(--color-red-500);
	--color-success: var(--color-green-500);
}
