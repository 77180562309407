.FormInput {
	width: 100%;
	padding: .5rem .8rem;
	margin: 0;
	border: var(--color-gray-200) 1px solid;
	border-radius: 4px;
	background-color: var(--color-white);
	box-sizing: border-box;
	outline-color: var(--color-blue-500);

		&:focus {
			border-color: var(--color-blue-500);
		}
}
