.Stack {
	display: grid;
	gap: .7rem;

	&--horizontal {
		width: fit-content;
		grid-auto-flow: column;
		grid-template-columns: auto;
	}

	&--vertical {
		grid-auto-flow: row;
		grid-template-rows: auto;
	}

	&--center {
		align-items: center;
	}
	&--bottom {
		align-items: end;
	}
	&--top {
		align-items: baseline;
	}
}
