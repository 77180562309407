.Loader {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	background: linear-gradient(180deg, #eee, #fff);

	&__wrapper {
		width: min-content;
		margin: 0 auto;
	}

	&__text {
		font-size: 3rem;
		margin: 0;
	}

	&__reason {
		font-size: 1rem;
		margin: 0;
	}
}

.LoaderInline {
	width: 100%;
	height: 100%;
	align-items: center;

	&__wrapper {
		width: min-content;
		margin: 0 auto;
		padding-top: 35%;
	}

	&__text {
		font-size: 3rem;
		margin: 0;
	}

	&__reason {
		font-size: 1rem;
		margin: 0;
	}
}
