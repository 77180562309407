.Breadcrumbs {
	&__link {
		color: var(--color-blue-500);
		text-decoration: none;

		&:hover {
			color: var(--color-blue-600);
			text-decoration: underline;
		}
	}

	&__delimiter {
		color: var(--color-gray-500);
		padding: 0 .5rem;
	}
}
