.NotificationBar {
	position: relative;

	&__message {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		background-color: var(--color-warning);
		color: var(--color-black);
		font-size: 1rem;
		padding: .2rem 2rem;
		box-sizing: border-box;
	}
}
