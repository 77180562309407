.Link {
	color: var(--color-blue-500);
	font-size: 1rem;
	text-decoration: none;
	transition: color 100ms ease-in-out;

	&:hover {
		color: var(--color-blue-700);
	}
}
