.EnvironmentInstanceDetail {
	display: grid;
	grid-template-columns: min-content min-content min-content;
	align-items: baseline;
	gap: 1rem;
	padding: .5rem;
	transition: background-color 20ms ease-in-out;

	&:hover {
		background: var(--color-gray-200);
		opacity: .8;
	}

	&__title,
	&__detail,
	&__state {
		white-space: nowrap;
	}

	&__title {
		font-size: 1rem;
		font-weight: normal;
		min-width: 250px;
	}
	&__state {
		min-width: 60px;
	}
	&__tag {
		font-size: .8rem;
		font-weight: bold;
	}
}
